import { useEffect } from 'react';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

function PrivacyPolicy() {
    const mainContent = useRef(null);
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <div className='p-5'>
                <div pb='9' pt='6' px={{ xs: '6', md: '10' }} ref={mainContent}>
                    <h1 style={{ fontSize: '2em' }}>
                        Privacy Policy for Koble
                    </h1>
                    <p>Last updated: January 05, 2024</p>
                    <h3 style={{ marginTop: '25px' }}>1. Introduction:</h3>
                    <p>
                        Welcome to Koble, an Alumni Community Networking
                        Application created by Roade Labs Pvt Ltd ("we," "us,"
                        or "our"). This Privacy Policy governs the collection,
                        use, and protection of information on our product
                        website, mobile app, and web app. By accessing or using
                        Koble, you agree to be bound by this Privacy Policy. If
                        you do not agree to this policy, please do not use
                        Koble.
                    </p>
                    <h3 style={{ marginTop: '25px' }}>
                        2. Information Collection:{' '}
                    </h3>
                    <p>
                        We collect information through our product website,
                        mobile app, and web app for the purpose of providing and
                        improving our services. This includes personal
                        information provided voluntarily by users and
                        automatically collected information for analytics.{' '}
                    </p>
                    <ul>
                        <li>
                            <h4>Product Website:</h4>
                            <p>
                                On our product website, we collect information
                                submitted voluntarily through the business
                                contact form.
                            </p>
                        </li>
                        <li>
                            <h4>Mobile App and Web App: </h4>
                            <p>
                                On our mobile app and web app, we collect
                                information such as account registration
                                details, profile information, usage data, and
                                device information.
                            </p>
                        </li>
                    </ul>
                    <h3 style={{ marginTop: '25px' }}>
                        3. Use of Information:{' '}
                    </h3>
                    <p>
                        We use the collected information to personalize user
                        experiences, communicate with users, and enhance our
                        services. We do not sell, trade, or transfer personal
                        information to third parties without user consent.
                    </p>
                    <h3 style={{ marginTop: '25px' }}>4. User Conduct:</h3>
                    <p>
                        Users must comply with all applicable laws and
                        regulations when using Koble. Unlawful or prohibited use
                        of Koble is not allowed.
                    </p>

                    <h3 style={{ marginTop: '25px' }}>5. User Content:</h3>
                    <p>
                        By submitting content on Koble, users grant us a
                        non-exclusive, worldwide license to use, reproduce,
                        modify, and distribute the content. Intellectual
                        property rights are retained by the users.
                    </p>

                    <h3 style={{ marginTop: '25px' }}>6. Security:</h3>
                    <p>
                        We implement industry-standard security measures to
                        protect user information from unauthorized access,
                        disclosure, alteration, and destruction.
                    </p>

                    <h3 style={{ marginTop: '25px' }}>7. Termination:</h3>
                    <p>
                        We reserve the right to terminate or suspend user
                        accounts and access to Koble at our discretion, without
                        prior notice, for any reason.
                    </p>

                    <h3 style={{ marginTop: '25px' }}>
                        8. Limitation of Liability:
                    </h3>
                    <p>
                        In no event shall Roade Labs Pvt Ltd be liable for any
                        indirect, incidental, special, consequential, or
                        punitive damages related to the use of Koble.
                    </p>

                    <h3 style={{ marginTop: '25px' }}>
                        9. Changes to Privacy Policy:
                    </h3>
                    <p>
                        We may update this Privacy Policy from time to time. Any
                        changes will be posted on this page, and users are
                        responsible for reviewing this policy periodically.
                    </p>

                    <h3 style={{ marginTop: '25px' }}>10. Contact Us:</h3>
                    <p>
                        If you have any questions or concerns about this Privacy
                        Policy, please contact us at{' '}
                        <a href='mailto:support@kobleapp.uk'>
                            support@kobleapp.uk
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
