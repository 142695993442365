import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';

function CityChapterForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {

    const submitHandler = (values, { setSubmitting }) => {
        
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        CITY: '',
        COUNTRY: '',
        CityChapter_Name: '',
        STATE: '',
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                CITY: Yup.string().required(),
                COUNTRY: Yup.string()
                    .matches(
                        /^[A-Za-z_ ]+$/,
                        'Country field only accepts Alphabets'
                    )
                    .required(),
                CityChapter_Name: Yup.string().required(),
                STATE: Yup.string()
                    .matches(
                        /^[A-Za-z_ ]+$/,
                        'State field only accepts Alphabets'
                    )
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='input'
                            required
                            name='CityChapter_Name'
                            label='City Chapter Name'
                        />
                    )}
                    <FormikControl control='input' required name='COUNTRY' />
                    <FormikControl control='input' required name='STATE' />
                    <FormikControl control='input' required name='CITY' />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(CityChapterForm);
