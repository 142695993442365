const endpoints = Object.freeze({
    authentication: {
        login: '/web/auth/login',
        activate: '/web/auth/activate',
        token: '/web/auth/token ',
        changePassword: '/web/auth/changePassword',
        forgotPassword: '/web/auth/admin/confirmForgotPassword',
    },
    notification: {
        admin: '/web/notificationsforadmin/list',
        delegate: '/web/notificationsforadmin/list/delegate/',
        read: '/web/notificationsforadmin/read',
        cch: 'web/notificationsforadmin/list/cch/',
        cchdelegate: '/web/notificationsforadmin/list/ccdelegate/',
    },
    alumniDetail: {
        get: '/web/profile/',
        getAlumniOnly: '/web/profile/alumni',
        getBatchAlumni: 'web/profile/userslist/',
        add: '/web/profile/add',
        update: '/web/profile/update',
        delete: '/web/profile/delete',
        resetPassword: '/web/profile/resetPassword',
        bulkUpload: '/web/bulkupload/upload/alumnidetails',
        bulkUploadTemplate: '/web/bulkupload/template/alumnidetails',
    },
    alumniPersonalTag: {
        get: '/web/personaltag/mapping/',
        add: '/web/personaltag/mapping/update',
        update: '/web/personaltag/mapping/update',
        delete: '/web/personaltag/mapping/delete',
    },
    cityChapters: {
        get: '/web/chaptertag/mapping/new',
        add: '/web/chaptertag/add',
        update: '/web/chaptertag/update',
        delete: '/web/chaptertag/delete',
    },
    cityChapterAlumniMap: {
        get: '/web/chaptertag/mapping/new',
        add: '/web/chaptertagcrudmapping/add',
        delete: '/web/chaptertagcrudmapping/delete',
    },
    statistics: {
        basics: '/stats/dashboardcomplete',
        ecMembers: '/stats/executivememberslist',
        cityChapterInclusion: '/stats/locationandcittychaptermapping',
        alumniJobIndustry: '/stats/count_alumniDetailsByIndustry',
        alumniJobOrg: '/stats/count_alumniDetailsByOrg',
        entreByIndustry: '/stats/count_entreAlumniDetailsByIndustry',
        alumniPerYear: '/stats/count_aluminiDetailByGraduationYear',
    },
    eventCalendar: {
        get: '/web/events/',
        delegate: '/web/batchdelegateview/events/',
        respond: '/web/events/approve',
        add: '/web/events/add',
        update: '/web/events/update',
        delete: '/web/events/delete',
    },
    rsvp: {
        get: '/web/events/rsvp/',
    },
    announcements: {
        get: '/web/announcement/',
        respond: '/web/announcement/respond',
        delete: 'announcement/delete',
    },
    alumniLookingForJob: {
        get: '/web/job/requirements',
        respond: '/web/job/jobrequirementstatus',
        delegate: '/web/batchdelegateview/jobrequirementlist/',
        delete: '/jobs/web/delete',
    },
    postedJobs: {
        get: '/web/job/posted',
        delegate: '/web/batchdelegateview/joblist/',
        respond: '/web/job/joblistingstatus',
        delete: '/jobList/delete',
    },
    markAsSafe: {
        get: '/web/marksafe/',
        add: '/web/marksafe/add',
        update: '/web/marksafe/update',
        delete: '/web/marksafe/delete',
    },
    safetyResponse: {
        get: '/web/marksafe/mapping/',
    },
    helpRequests: {
        get: '/web/requesthelp/',
        delegate: '/web/batchdelegateview/helplist/',
        respond: '/web/requesthelp/respond',
        delete: '/requesthelp/delete',
    },
    covidVaccineStatus: {
        get: '/web/vaccine',
    },
    governingCouncil: {
        get: '/web/ecmembers/',
        add: '/web/ecmembers/add',
        update: '/web/ecmembers/update',
        delete: '/web/ecmembers/delete',
        activate: '/web/ecmembers/activate',
    },
    cityChapterHead: {
        get: '/web/chapterhead/',
        add: '/web/chapterhead/add',
        update: '/web/chapterhead/update',
        delete: '/web/chapterhead/delete',
        // activate: '/web/chapterhead/activate',
    },
    personalTags: {
        get: '/web/personaltag/',
        add: '/web/personaltag/add',
        update: '/web/personaltag/update',
        delete: '/web/personaltag/delete',
    },
    topicOfInterest: {
        get: '/web/topicofinterest/enum/',
        add: '/web/topicofinterest/enum/add',
        update: '/web/topicofinterest/enum/update',
        delete: '/web/topicofinterest/enum/delete',
        approve: '/web/topicofinterestapprove/approve',
        reject: '/web/topicofinterestapprove/reject',
    },
    course: {
        get: '/web/courses',
        add: '/web/courses/add',
        update: '/web/courses/update',
        delete: '/web/courses/delete',
    },
    benefitProviders: {
        get: '/web/insuranceprovider/',
        add: '/web/insuranceprovider/add',
        update: '/web/insuranceprovider/update',
        delete: '/web/insuranceprovider/delete',
    },
    healthInsuranceDetails: {
        get: '/web/insurance/health',
        delete: 'healthinsurance/web/delete',
    },
    lifeInsuranceDetails: {
        get: '/web/insurance/life',
        delete: 'lifeinsurance/delete',
    },
    batch: {
        get: '/web/batchdetails/list',
        add: '/web/batchdetails/add',
        update: '/web/batchdetails/update',
        delete: '/web/batchdetails/delete',
        activate: '/web/batchdelegate/activate',
    },
    batchHeads: {
        get: '/web/batchdelegate/list',
        add: '/web/batchdelegate/add',
        update: '/web/batchdelegate/update',
        delete: '/web/batchdelegate/delete',
    },
    industry: {
        get: '/common/org/list/industry',
    },
    organization: {
        get: '/common/org/list/organization',
    },
});

export default endpoints;
