import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useLocation } from 'react-router-dom';

function RSVP() {
    const { state } = useLocation();
    const fetchUtils = useAxiosGet(endpoints.rsvp.get + state);

    const cellModifier = {
        IsEmployed: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsActive: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsEntrepreneur: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsLookingForAChange: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        RSVP_STATUS: ({ value }) => {
            const types = {
                0: 'NOT GOING',
                1: 'GOING',
                2: 'MAY BE',
            };
            return types[value];
        },
    };
    return (
        <>
            <CRUDTable
                {...viewProps.RSVP}
                cellModifier={cellModifier}
                insertable={false}
                immutable
                irremovable
                {...fetchUtils}
            />
        </>
    );
}

export default RSVP;
