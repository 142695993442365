import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import PostWithWarning from '../../components/PostWithWarning';
import ModalWrapper from '../../components/ModalWrapper';
import { useAuthenticationState } from '../../context/Auth.context';
import StatusIndicator from '../../components/StatusIndicator';

function HelpRequests() {
    const { user } = useAuthenticationState();

    let getApi = endpoints.helpRequests.get;

    if (['DELEGATE', 'DELEGATE+CCH'].includes(user.Role)) {
        getApi = endpoints.helpRequests.delegate + user.DELEGATE_BATCH_CODE;
    }

    const fetchUtils = useAxiosGet(getApi);

    const cellModifier = {
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={'Approve Request ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Alumni_HelpRequest_RESPONSE_ID !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.helpRequests.respond}
                        configBody={{
                            Alumni_HelpRequest_RESPONSE_COMMENT: 'nill',
                            Alumni_HelpRequest_RESPONSE_ID: '1',
                            Alumni_HelpRequest_ID:
                                row.original.Alumni_HelpRequest_ID,
                            Alumni_HelpRequest_AlumniID:
                                row.original.Alumni_HelpRequest_AlumniID,
                            Alumni_HelpRequest_TITLE:
                                row.original.Alumni_HelpRequest_TITLE,
                            NOTIFICATION_CONTENT: 'Help request approved',
                        }}
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Block Request ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Alumni_HelpRequest_RESPONSE_ID !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.helpRequests.respond}
                        configBody={{
                            Alumni_HelpRequest_RESPONSE_COMMENT: 'nill',
                            Alumni_HelpRequest_RESPONSE_ID: '2',
                            Alumni_HelpRequest_ID:
                                row.original.Alumni_HelpRequest_ID,
                            Alumni_HelpRequest_AlumniID:
                                row.original.Alumni_HelpRequest_AlumniID,
                            Alumni_HelpRequest_TITLE:
                                row.original.Alumni_HelpRequest_TITLE,
                            NOTIFICATION_CONTENT: 'Help request rejected',
                        }}
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        Alumni_HelpRequest_TYPE: ({ value }) => {
            const types = {
                1: 'MEDICAL RELATED',
                2: 'CALAMITY RELATED',
                3: 'QUERIES',
                4: 'PERSONAL',
                5: 'LEGAL ADVICE',
                6: 'EMPLOYMENT RELATED',
            };
            return types[value];
        },
        Alumni_HelpRequest_PRIORITY: ({ value }) => {
            const types = {
                1: 'URGENT',
                2: 'IMMEDIATE',
                3: 'NOT URGENT',
            };
            return types[value];
        },
        Alumni_HelpRequest_STATUS: ({ value }) => {
            const types = {
                0: 'CANCELLED',
                1: 'ACTIVE',
                2: 'EXPIRED',
                3: 'RESPONDED',
                4: 'CLOSED',
            };
            return types[value];
        },
        Alumni_HelpRequest_BROADCAST_TYPE: ({ value }) => {
            const types = {
                1: 'ALL',
                2: 'EPGP',
                3: 'PGP',
                4: 'EPGP ONLINE',
                5: 'ENTREPRENEUR',
            };
            return types[value];
        },
        Alumni_HelpRequest_RESPONSE_ID: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        Alumni_HelpRequest_ExpiryDate: ({ value }) =>
            new Date(value).toLocaleDateString(),
    };
    
    return (
        <>
            <CRUDTable
                {...viewProps.HelpRequests}
                cellModifier={cellModifier}
                // irremovable
                immutable
                insertable={false}
                {...fetchUtils}
                endpoints = {endpoints.helpRequests}
            />
        </>
    );
}

export default HelpRequests;
