import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import { checkIsoAndReturnYMDFormat } from '../../helpers/dateFunctions';
import { Col, Row } from 'react-bootstrap';
import { useLabelProvider } from '../../context/LabelProvider';

function MarkAsSafeForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { setLabels } = useLabelProvider();

    useEffect(() => {
        setLabels('MarkAsSafe');
    }, []);

    const { response, loading } = useAxiosGetMultiple({
        cityChapters: endpoints.cityChapters.get,
    });
    const { cityChapters } = response;

    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };

        for (const key in body) {
            if (body[key] === 0) {
                body[key] = '0';
            }
        }

        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    if (updateValues) {
        updateValues.Alumni_MarkAsSafe_BROADCAST_TYPE =
            updateValues.Alumni_MarkAsSafe_BROADCAST_TYPE_ID;
        updateValues.Alumni_MarkAsSafe_EventTYPE =
            updateValues.Alumni_MarkAsSafe_EventTYPE_ID;
        updateValues.Alumni_MarkAsSafe_STATUS =
            updateValues.Alumni_MarkAsSafe_STATUS_ID;
    }
    const initialFormValues = updateValues || {
        Alumni_MarkAsSafe_EventName: '',
        Alumni_MarkAsSafe_EventTYPE: '',
        Alumni_MarkAsSafe_STATUS: '',
        Alumni_MarkAsSafe_BROADCAST_TYPE: '',
        // Alumni_MarkAsSafe_BATCH_CODE: '',
        Alumni_MarkAsSafe_CITYCHAPTER_CODE: '',
        Alumni_MarkAsSafe_EventStartDate: '',
        Alumni_MarkAsSafe_EventEndDate: '',
    };
    const broadcastType = {
        ALL: 0,
        EPGP: 1,
        PGP: 2,
        'EPGP ONLINE': 3,
        Entrepreneurs: 4,
    };
    const eventType = {
        FLOOD: 1,
        EPIDEMIC: 2,
        'POLITICAL UNREST': 3,
        OTHERS: 4,
    };
    const eventStatus = {
        CANCELLED: 0,
        ACTIVE: 1,
        EXPIRED: 2,
        RESPONDED: 3,
        CLOSED: 4,
    };
    const preSelectedOption = {};
    if (updateValues) {
        // preSelectedOption.batch = batches.find(
        //     (batch) =>
        //         Number(batch.BATCH_CODE) ===
        //         Number(initialFormValues.Alumni_MarkAsSafe_BATCH_CODE)
        // );
        preSelectedOption.cityChapter = cityChapters.find(
            (cityChapter) =>
                Number(cityChapter.CityChapter_ID) ===
                Number(initialFormValues.Alumni_MarkAsSafe_CITYCHAPTER_CODE)
        );
        updateValues.Alumni_MarkAsSafe_EventStartDate =
            checkIsoAndReturnYMDFormat(
                updateValues.Alumni_MarkAsSafe_EventStartDate
            );
        updateValues.Alumni_MarkAsSafe_EventEndDate =
            checkIsoAndReturnYMDFormat(
                updateValues.Alumni_MarkAsSafe_EventEndDate
            );
        // if (updateValues.Alumni_MarkAsSafe_BATCH_CODE === 'N/A') {
        //     updateValues.Alumni_MarkAsSafe_BATCH_CODE = '';
        // }
        if (updateValues.Alumni_MarkAsSafe_CITYCHAPTER_CODE === 'N/A') {
            updateValues.Alumni_MarkAsSafe_CITYCHAPTER_CODE = '';
        }
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Alumni_MarkAsSafe_EventName: Yup.string().required(),
                Alumni_MarkAsSafe_EventTYPE: Yup.string().required(),
                Alumni_MarkAsSafe_STATUS: Yup.string().required(),
                Alumni_MarkAsSafe_BROADCAST_TYPE: Yup.string().required(),
                // Alumni_MarkAsSafe_BATCH_CODE: Yup.string().when(
                //     'Alumni_MarkAsSafe_BROADCAST_TYPE',
                //     {
                //         is: '0',
                //         then: () => Yup.string().nullable(),
                //         otherwise: () => Yup.string().required(),
                //     }
                // ),
                // Alumni_MarkAsSafe_CITYCHAPTER_CODE: Yup.string().when(
                //     'Alumni_MarkAsSafe_BROADCAST_TYPE',
                //     {
                //         is: '0',
                //         then: () => Yup.string().nullable(),
                //         otherwise: () => Yup.string().required(),
                //     }
                // ),
                Alumni_MarkAsSafe_EventStartDate: Yup.date()
                    .max(Date(), "Can't be beyond today")
                    .required(),
                Alumni_MarkAsSafe_EventEndDate: Yup.date()
                    .min(
                        Yup.ref('Alumni_MarkAsSafe_EventStartDate'),
                        'End Date must be later than Start Date'
                    )
                    .max(Date(), "Can't be beyond today")
                    .nullable(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                    <Row>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='Alumni_MarkAsSafe_EventName'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Alumni_MarkAsSafe_EventTYPE'
                            >
                                <option value=''>Select Event Type</option>
                                {Object.keys(eventType).map((type) => (
                                    <option key={type} value={eventType[type]}>
                                        {type}
                                    </option>
                                ))}
                            </FormikControl>
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Alumni_MarkAsSafe_STATUS'
                            >
                                <option>Select Event Status</option>
                                {Object.keys(eventStatus).map((type) => (
                                    <option
                                        key={type}
                                        value={eventStatus[type]}
                                    >
                                        {type}
                                    </option>
                                ))}
                            </FormikControl>
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Alumni_MarkAsSafe_BROADCAST_TYPE'
                                label='BROADCAST TYPE'
                                // onChange={(e) => {
                                //     setFieldValue(
                                //         'Alumni_MarkAsSafe_BROADCAST_TYPE',
                                //         e.target.value
                                //     );
                                //     // setFieldValue(
                                //     //     'Alumni_MarkAsSafe_BATCH_CODE',
                                //     //     ''
                                //     // );
                                //     // setFieldValue(
                                //     //     'Alumni_MarkAsSafe_CITYCHAPTER_CODE',
                                //     //     ''
                                //     // );
                                // }}
                            >
                                <option>Select Broadcast Type</option>
                                <option value='0'>All</option>
                                {cityChapters.map((city) => (
                                    <option
                                        key={city.CityChapter_ID}
                                        value={city.CityChapter_ID}
                                    >
                                        {city.CityChapter_Name}
                                    </option>
                                ))}
                            </FormikControl>
                        </Col>
                        {/* {Number(values.Alumni_MarkAsSafe_BROADCAST_TYPE) !==
                            0 && (
                            <>
                                <Col sm='6'>
                                    <FormikControl
                                        control='select-advanced'
                                        required
                                        options={batches}
                                        defaultValue={preSelectedOption.batch}
                                        optionValue='BATCH_CODE'
                                        optionLabel='BATCH_NAME'
                                        name='Alumni_MarkAsSafe_BATCH_CODE'
                                        label='Batch'
                                    />
                                </Col>
                                <Col sm='6'>
                                    <FormikControl
                                        control='select-advanced'
                                        required
                                        options={cityChapters}
                                        defaultValue={
                                            preSelectedOption.cityChapter
                                        }
                                        optionValue='CityChapter_ID'
                                        optionLabel='CityChapter_Name'
                                        name='Alumni_MarkAsSafe_CITYCHAPTER_CODE'
                                        label='City Chapter'
                                    />
                                </Col>
                            </>
                        )} */}
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='Alumni_MarkAsSafe_EventStartDate'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                // required
                                type='date'
                                name='Alumni_MarkAsSafe_EventEndDate'
                            />
                        </Col>
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(MarkAsSafeForm);
