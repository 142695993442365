import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import AlumniForm from './AlumniForm';
import viewProps from '../viewProps';
import withAlert from '../../components/withAlert';
import BulkUpload from '../../components/BulkUpload';
import { useLocation } from 'react-router-dom';

function AlumniDetails() {
    const fetchUtils = useAxiosGet(endpoints.alumniDetail.get);

    const { state } = useLocation();

    const yesOrNo = {
        0: 'No',
        1: 'Yes',
    };

    const cellModifier = {
        DateOfBirth: ({ value }) => new Date(value).toDateString(),
        IsEmployed: ({ value }) => yesOrNo[value] ?? value,
        IsActive: ({ value }) => {
            const status = {
                1: 'Active',
                2: 'Inactive',
                3: 'Deactivated',
            };
            return status[value];
        },
        Type: ({ value }) => {
            const status = {
                1: 'Alumni',
                2: 'Student',
                3: 'Faculty',
            };
            return status[value];
        },
        IsEntrepreneur: ({ value }) => yesOrNo[value] ?? value,
        IsLookingForAChange: ({ value }) => yesOrNo[value] ?? value,
        PrivacySettings: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Full Access';
                case 2:
                    return 'No Access';
                case 3:
                    return 'Limited Access';

                default:
                    return value;
            }
        },
        // ResetPassword: ({ row }) => (
        //     <ModalWrapper
        //         modalAttrs={{ size: 'sm' }}
        //         modalTitle='Reset password'
        //         renderModalBody={(closeModal) => (
        //             <PostWithWarning
        //                 endpoint={endpoints.alumniDetail.resetPassword}
        //                 configBody={{
        //                     PERSONAL_EMAILID: row.original.PERSONAL_EMAILID,
        //                 }}
        //                 onSuccess={() => {
        //                     showAlert('success', 'Password reset successfull. New password has been sent to mail');
        //                 }}
        //                 onError={() => {
        //                     showAlert('error', 'Something went wrong');
        //                 }}
        //                 onCancel={closeModal}
        //             />
        //         )}
        //     >
        //         <Button
        //             variant='outline-warning btnAnime'
        //             className='px-1 py-0'
        //         >
        //             <small>Reset Password</small>
        //         </Button>
        //     </ModalWrapper>
        // ),
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniDetail}
                Form={AlumniForm}
                formSize='lg'
                {...viewProps.AlumniDetails}
                cellModifier={cellModifier}
                {...fetchUtils}
                headerExtras={
                    <BulkUpload
                        uploadUrl={endpoints.alumniDetail.bulkUpload}
                        templateUrl={endpoints.alumniDetail.bulkUploadTemplate}
                        filename='Alumni Details'
                    />
                }
                initialFilterValue={state || ''}
                // irremovable
                // immutable
                // insertable = {false}
            />
        </>
    );
}

export default AlumniDetails;
