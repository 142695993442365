import React, { useState } from 'react';
import {
    CloseButton,
    Form,
    // FormCheck,
    FormControl,
    Navbar,
} from 'react-bootstrap';
import { useAuthenticationState } from '../../context/Auth.context';
import { ReactComponent as Search } from '../../asset/icons/Search.svg';
import { ReactComponent as Logout } from '../../asset/icons/Logout.svg';
import Notification from '../Notification';
import { useLocation, useNavigate } from 'react-router-dom';
import Profile from './Profile';

function AdminNavbar({ notifications, refetchNotification }) {
    const { logout, user } = useAuthenticationState();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [search, setSearch] = useState('');

    return (
        <Navbar className=' pt-3 pb-4' variant='light' bg='transparent'>
            {(user.Role === 'ADMIN' || user.Role === 'GCM') && ( //No other roles has access to alumni details table
                <Form
                    className='w-50 d-flex position-relative'
                    onSubmit={(e) => {
                        e.preventDefault();
                        navigate('/alumni-details', {
                            state: search,
                        });
                        setSearch('');
                    }}
                >
                    {pathname !== '/alumni-details' && (
                        <>
                            <FormControl
                                className='border-0 rounded-smooth clearFocusDecorate ps-4'
                                placeholder='Search Alumni'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            {search && (
                                <CloseButton
                                    className='position-absolute clearFocusDecorate'
                                    style={{
                                        top: '9px',
                                        left: '0px',
                                        transform: 'scale(.6)',
                                    }}
                                    onClick={() => {
                                        setSearch('');
                                    }}
                                />
                            )}
                            <button
                                style={{
                                    backgroundColor: '#5FB3F6',
                                    borderRadius: '10px',
                                    marginLeft: '-8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '40px',
                                    minWidth: '40px',
                                    cursor: 'pointer',
                                    border: 'none',
                                }}
                                type='submit'
                            >
                                <Search />
                            </button>
                        </>
                    )}
                </Form>
            )}
            <span className='me-auto' />
            <Notification
                notifications={notifications}
                refetch={refetchNotification}
            />

            <Profile user={user} />

            <div className='mx-3 pt-2' type='button' onClick={logout}>
                <Logout />
            </div>
        </Navbar>
    );
}

export default AdminNavbar;
