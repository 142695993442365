import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuthenticationState } from '../context/Auth.context';
import Dashboard from '../layout/Dashboard';
import Login from '../views/login/Login';
import allRoutes from './routes';
import TermsAndCondtions from '../views/Terms';
import PrivacyPolicy from '../views/PrivacyPolicy';

function GenerateRoutes() {
    const { user } = useAuthenticationState();
    let location = useLocation();

    let routes = [];

    const delegateRoutePaths = [
        'events',
        'help-requests',
        'job-seekers',
        'posted-jobs',
        'announcements',
        'rsvp',
    ];

    const cchRoutePaths = [
        'city-chapters',
        'mark-as-safe',
        'safety-response',
        'city-chapter-map',
    ];

    switch (user.Role?.toLowerCase()) {
        case 'superadmin':
        case 'admin':
        case 'gcm':
            routes = [...allRoutes];
            break;

        case 'delegate':
            routes = allRoutes.filter((route) =>
                delegateRoutePaths.includes(route.path)
            );
            break;

        case 'cch':
            routes = allRoutes.filter((route) =>
                cchRoutePaths.includes(route.path)
            );
            break;

        case 'delegate+cch':
            routes = allRoutes.filter((route) =>
                [...delegateRoutePaths, ...cchRoutePaths].includes(route.path)
            );
            break;

        default:
            break;
    }

    return (
        <Routes>
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/terms' element={<TermsAndCondtions />} />
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path={'/'} element={<Dashboard routes={routes} />}>
                {routes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={<route.page />}
                    />
                ))}
            </Route>

            <Route
                path='*'
                element={
                    <Navigate
                        to={'/login'}
                        state={{ from: location }}
                        replace
                    />
                }
            />
        </Routes>
    );
}

export default GenerateRoutes;
