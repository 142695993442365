import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from './Loader';
import ModalWrapper from './ModalWrapper';
import { ReactComponent as Upload } from '../asset/icons/Upload.svg';
import withAlert from './withAlert';
import extractErrorFromRes from '../helpers/extractErrorFromRes';

function BulkUpload({ uploadUrl, templateUrl, filename, showAlert, ...rest }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const downloadTemplate = () => {
        setLoading(true);
        axios({
            url: templateUrl,
            method: 'GET',
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename + '.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                showAlert('error', extractErrorFromRes(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const upload = (afterUpload) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        axios
            .post(uploadUrl, formData)
            .then((res) => {
                setLoading(false);
                setSelectedFile(null);
                afterUpload();
            })
            .catch((err) => {
                setLoading(false);
                showAlert('error', extractErrorFromRes(err));
            });
    };

    return (
        <>
            <ModalWrapper
                modalTitle='Upload Details via xlsx'
                onHiding={() => {
                    setSelectedFile(null);
                }}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <div>
                        <p>
                            If template is not available download{' '}
                            <span
                                className='text-primary'
                                type='button'
                                onClick={downloadTemplate}
                            >
                                here
                            </span>
                        </p>

                        <input
                            type='file'
                            className='form-control'
                            onChange={(e) => {
                                setSelectedFile(e.target.files[0]);
                            }}
                        />

                        <div className='text-center mt-3'>
                            <Button
                                disabled={!selectedFile}
                                onClick={() => {
                                    upload(closeModal);
                                }}
                                {...rest}
                            >
                                Upload
                            </Button>

                            <Button
                                variant='light'
                                className='border mx-2'
                                onClick={() => {
                                    setSelectedFile(null);
                                    closeModal();
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                        {loading && <Loader />}
                    </div>
                )}
            >
                <Button className='primaryBtn btnAnime ms-4 px-3 py-1'>
                    <Upload />
                </Button>
            </ModalWrapper>
        </>
    );
}

export default withAlert(BulkUpload);
