import React from 'react';
import { ReactComponent as NotificationIcon } from '../asset/icons/Notification.svg';
import { ReactComponent as NotificationActive } from '../asset/icons/NotificationActive.svg';
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import endpoints from '../endpoints';

function Notification({ notifications, refetch }) {
    let allRead = true;

    const navigate = useNavigate();

    const unreadNotifications = notifications.filter(
        (item) => item.MARK_READ_ID !== 1
    );
    if (unreadNotifications.length !== 0) {
        allRead = false;
    }

    const DropItems = ({ item }) => (
        <div
            className='border-top'
            style={{
                backgroundColor:
                    item.MARK_READ_ID === 1 ? '#F0F0F0' : 'transparent',
            }}
        >
            <div
                eventKey='1'
                className='px-3 py-2 '
                type='button'
                key={item.NOTIFICATION_ID}
                onClick={() => {
                    navigate('/' + item.NOTIFICATION_LOCATION);

                    if (item.MARK_READ_ID !== 1) {
                        axios
                            .post(endpoints.notification.read, item)
                            .then((res) => {
                                refetch();
                            });
                    }
                }}
            >
                <div className='position-absolute hover-overlay ' />
                <p
                    className='small fw-bold mb-1'
                    style={{ width: '250px', whiteSpace: 'normal' }}
                >
                    {item.ALUMNI_Name} : {item.NOTIFICATION_CONTENT}
                </p>
                <span className='small'>
                    {item.Alumni_MarkAsSafe_EventName}
                </span>
            </div>
        </div>
    );

    return (
        <div className='custom-dropdown custom-tab'>
            <Dropdown align='end'>
                <Dropdown.Toggle
                    variant='transparent'
                    className='primaryBtn shadow-sm'
                    style={{ padding: '12px 16px' }}
                >
                    {allRead ? <NotificationIcon /> : <NotificationActive />}
                </Dropdown.Toggle>

                <Dropdown.Menu className='border-0 shadow-sm pb-3'>
                    {notifications.length ? (
                        <Tab.Container
                            defaultActiveKey='unread'
                            className='bg-danger'
                        >
                            <Nav
                                variant='pills'
                                className='my-3 justify-content-center nav-tabs mx-auto'
                                style={{ width: '150px' }}
                            >
                                <Nav.Item className='w-50 text-center'>
                                    <Nav.Link
                                        type='button'
                                        className='p-2 py-0 small'
                                        eventKey='unread'
                                    >
                                        Unread
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='w-50 text-center'>
                                    <Nav.Link
                                        type='button'
                                        className='p-2 py-0 small'
                                        eventKey='all'
                                    >
                                        All
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane
                                    eventKey='all'
                                    title='All'
                                    style={{
                                        maxHeight: '300px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {notifications.map((item) => (
                                        <DropItems
                                            item={item}
                                            key={item.NOTIFICATION_ID}
                                        />
                                    ))}
                                </Tab.Pane>

                                <Tab.Pane
                                    eventKey='unread'
                                    title='Unread'
                                    style={{
                                        maxHeight: '300px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {unreadNotifications.length > 0 ? (
                                        unreadNotifications.map((item) => (
                                            <DropItems
                                                item={item}
                                                key={item.NOTIFICATION_ID}
                                            />
                                        ))
                                    ) : (
                                        <p
                                            style={{
                                                width: '250px',
                                            }}
                                            className='h6 text-center text-secondary'
                                        >
                                            No Messages
                                        </p>
                                    )}
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    ) : (
                        <p className='h6 p-3 text-secondary'>Seems empty</p>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default Notification;
