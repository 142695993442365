import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import PersonalTagForm from './PersonalTagForm';

function PersonalTags() {
    const fetchUtils = useAxiosGet(endpoints.personalTags.get);
    return (
        <>
            <CRUDTable
                endpoints={endpoints.personalTags}
                {...viewProps.PersonalTags}
                Form = {PersonalTagForm}
                {...fetchUtils}
                // irremovable
            />
        </>
    );
}

export default PersonalTags;
