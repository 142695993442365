import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';

function BenefitProviderForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
    classification,
}) {
    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };
        body.Alumni_InsuranceProvider_Status = String(
            body.Alumni_InsuranceProvider_Status
        );
        
        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert('error', err.response.data?.statusText || 'Something went wrong');
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        Alumni_InsuranceProvider_NAME: '',
        Alumni_InsuranceProvider_URL: '',
        Alumni_InsuranceProvider_Status: '',
        Alumni_InsuranceProvider_Classification: classification,
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Alumni_InsuranceProvider_NAME: Yup.string().required(),
                Alumni_InsuranceProvider_URL: Yup.string().url().required(),
                Alumni_InsuranceProvider_Status: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormikControl
                        required
                        name='Alumni_InsuranceProvider_NAME'
                        label='Provider Name'
                    />
                    <FormikControl
                        required
                        type='url'
                        name='Alumni_InsuranceProvider_URL'
                        label='Provider URL'
                    />

                    <FormikControl
                        control='check'
                        required
                        type='radio'
                        name='Alumni_InsuranceProvider_Status'
                        label='Provider Status'
                        options={[
                            { key: 'Active', value: '1' },
                            { key: 'Inactive', value: '2' },
                        ]}
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(BenefitProviderForm);
