import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import BatchForm from './BatchForm';

function Batches() {
    const fetchUtils = useAxiosGet(endpoints.batch.get);

    return (
        <>
            <CRUDTable
                endpoints={endpoints.batch}
                {...viewProps.Batch}
                Form = {BatchForm}
                // irremovable
                {...fetchUtils}
            />
        </>
    );
}

export default Batches;
