import React from "react";
import CRUDTable from "../../components/table/CRUDTable";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import GoverninCouncilForm from "./GoverninCouncilForm";

function GoverningCouncil() {
    const fetchUtils = useAxiosGet(endpoints.governingCouncil.get);

    const cellModifier = {
        EC_Member_Elected_Date:({value}) => new Date(value).toDateString(),
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.governingCouncil}
                {...viewProps.GoverningCouncil}
                cellModifier={cellModifier}
                Form = {GoverninCouncilForm}
                {...fetchUtils}
                addBtnLabel = 'Add Member'
            />
        </>
    );
}

export default GoverningCouncil;
