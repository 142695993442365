import AlumniDetails from "../views/alumniDetails/AlumniDetails";
import AlumniLookingForJob from "../views/alumniLookingForJob/AlumniLookingForJob";
import AlumniPersonalTag from "../views/alumniPersonalTag/AlumniPersonalTag";
import Announcements from "../views/announcements/Announcements";
import Batches from "../views/batches/Batches";
import BatchHeads from "../views/batchHeads/BatchHeads";
import CityChapterAlumniMap from "../views/cityChapterAlumniMap/CityChapterAlumniMap";
import CityChapterHead from "../views/cityChapterHead/CityChapterHead";
import CityChapters from "../views/cityChapters/CityChapters";
// import CovidVaccineStatus from "../views/covidVaccineStatus/CovidVaccineStatus";
import Dashboard from "../views/dashboard/Dashboard";
import EventCalendar from "../views/eventCalendar/EventCalendar";
import GoverningCouncil from "../views/governingCouncil/GoverningCouncil";
import HelpRequests from "../views/helpRequests/HelpRequests";
import BenefitProviders from "../views/benefitProviders/BenefitProviders";
import MarkAsSafe from "../views/markAsSafe/MarkAsSafe.js";
import PersonalTags from "../views/personalTags/PersonalTags";
import PostedJobs from "../views/postedJobs/PostedJobs";
import RSVP from "../views/eventCalendar/RSVP";
import SafetyResponse from '../views/safetyResponse/SafetyResponse';
import TopicOfInterest from "../views/topicOfInterest/TopicOfInterest";
import BenefitDetails from "../views/insuranceDetails/BenefitDetails";
import Courses from "../views/courses/Courses";

var routes = [
    {
        path: '',
        name: 'Dashboard',
        page: Dashboard,
    },
    {
        path: 'alumni-details',
        name: 'Alumni Details',
        page: AlumniDetails,
    },
    {
        path: 'alumni-personaltag',
        name: 'Alumni Personal Tag Mapping',
        page: AlumniPersonalTag,
    },
    {
        path: 'city-chapters',
        name: 'City Chapters',
        page: CityChapters,
    },
    {
        path: 'city-chapter-map',
        name: 'City Chapters Alumni Mapping',
        page: CityChapterAlumniMap,
        hiddenFromMenu: true,
    },
    {
        path: 'events',
        name: 'Event Calendar',
        page: EventCalendar,
    },
    {
        path: 'rsvp',
        name: 'RSVP',
        page: RSVP,
        hiddenFromMenu: true,
    },
    {
        path: 'announcements',
        name: 'Announcements',
        page: Announcements,
    },
    {
        path: 'job-seekers',
        name: 'Alumni Looking for job',
        page: AlumniLookingForJob,
    },
    {
        path: 'posted-jobs',
        name: 'Posted Jobs',
        page: PostedJobs,
    },
    {
        path: 'mark-as-safe',
        name: 'Mark as Safe Events',
        page: MarkAsSafe,
    },
    {
        path: 'safety-response',
        name: 'Safety Response',
        page: SafetyResponse,
    },
    {
        path: 'help-requests',
        name: 'Help Requests',
        page: HelpRequests,
    },
    {
        path: 'benefit-details',
        name: 'Benefit Details',
        page: BenefitDetails,
        // module: 'Benefit Details'
    },
    // {
    //     path: 'vaccine-details',
    //     name: 'Vaccine Status',
    //     page: CovidVaccineStatus,
    // },
    {
        path: 'batch',
        name: 'Batch',
        page: Batches,
        module: 'enum',
    },
    {
        path: 'batch-head',
        name: 'Batch Representatives',
        page: BatchHeads,
        module: 'enum',
    },
    {
        path: 'gc-council',
        name: 'Governing Council',
        page: GoverningCouncil,
        module: 'enum',
    },
    {
        path: 'city-chapter-head',
        name: 'City Chapter Head',
        page: CityChapterHead,
        module: 'enum',
    },
    {
        path: 'courses',
        name: 'Courses',
        page: Courses,
        module: 'enum',
    },
    {
        path: 'personal-tags',
        name: 'Personal Tags',
        page: PersonalTags,
        module: 'enum',
    },
    {
        path: 'topic-of-interest',
        name: 'Topic of Interest',
        page: TopicOfInterest,
        module: 'enum',
    },
    {
        path: 'insurance-providers',
        name: 'Benefit Providers',
        page: BenefitProviders,
        module: 'enum',
    },
];
export default routes;
