import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import ModalWrapper from '../../components/ModalWrapper';
import PostWithWarning from '../../components/PostWithWarning';
import { useAuthenticationState } from '../../context/Auth.context';
import StatusIndicator from '../../components/StatusIndicator';

function Announcements() {
    const { user } = useAuthenticationState();

    const { response, ...fetchUtils } = useAxiosGet(
        endpoints.announcements.get
    );

    let announcements = [...response];

    if (['DELEGATE', 'DELEGATE+CCH'].includes(user.Role)) {
        announcements = announcements.filter(
            (ann) =>
                Number(ann.Alumni_AnnouncmentReq_BATCH_CODE) ===
                Number(user.DELEGATE_BATCH_CODE)
        );
    }

    const cellModifier = {
        BATCH_NAME: ({ value }) => (value === 'N/A' ? 'ALL' : value),
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={'Approve Announcement ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Alumni_AnnouncmentReq_RESPONSE_ID !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.announcements.respond}
                        configBody={{
                            Alumni_AnnouncmentReq_RESPONSE_COMMENT: 'nil',
                            Alumni_AnnouncmentReq_RESPONSE_ID: '1',
                            Alumni_AnnouncmentReq_ID:
                                row.original.Alumni_AnnouncmentReq_ID,
                            Alumni_AnnouncmentReq_TITLE:
                                row.original.Alumni_AnnouncmentReq_TITLE,
                            NOTIFICATION_CONTENT: 'Announcement Approved',
                            CREATEDBY: row.original.CREATEDBY,
                        }}
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Block Announcement ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Alumni_AnnouncmentReq_RESPONSE_ID !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.announcements.respond}
                        configBody={{
                            Alumni_AnnouncmentReq_RESPONSE_COMMENT: 'nil',
                            Alumni_AnnouncmentReq_RESPONSE_ID: '2',
                            Alumni_AnnouncmentReq_ID:
                                row.original.Alumni_AnnouncmentReq_ID,
                            Alumni_AnnouncmentReq_TITLE:
                                row.original.Alumni_AnnouncmentReq_TITLE,
                            NOTIFICATION_CONTENT: 'Announcement Rejected',
                            CREATEDBY: row.original.CREATEDBY,
                        }}
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        Alumni_AnnouncmentReq_RESPONSE_ID: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        Alumni_AnnouncmentReq_ExpiryDate: ({ value }) =>
            new Date(value).toLocaleDateString(),
    };
    return (
        <>
            <CRUDTable
                {...viewProps.Announcements}
                cellModifier={cellModifier}
                // irremovable
                immutable
                insertable={false}
                {...fetchUtils}
                response={announcements}
                endpoints = {endpoints.announcements}
            />
        </>
    );
}

export default Announcements;
