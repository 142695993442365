import React from "react";
import CRUDTable from "../../components/table/CRUDTable";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import { useLocation } from "react-router-dom";

function SafetyResponse() {
    const fetchUtils = useAxiosGet(endpoints.safetyResponse.get);
    const {state} = useLocation()
    let response = []
    if(state){
        response = fetchUtils.response.filter(
            (res) => Number(res.Alumni_MarkAsSafe_EventID) === Number(state)
        );
    }else{
        response = fetchUtils.response;
    }
    const cellModifier = {
        DateOfBirth: ({ value }) => new Date(value).toDateString(),
    };
    return (
        <>
            <CRUDTable
                {...viewProps.SafetyResponse}
                cellModifier={cellModifier}
                insertable = {false}
                immutable 
                irremovable
                {...fetchUtils}
                response = {response}
            />
        </>
    );
}

export default SafetyResponse;
