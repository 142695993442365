import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import CityChapterForm from './CityChapterForm';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';

function CityChapters() {
    const fetchUtils = useAxiosGet(endpoints.cityChapters.get);
    const { user } = useAuthenticationState();

    let cityChapters = [];

    if (user?.CCH?.CityChapter_ID) {
        cityChapters = fetchUtils.response.filter(
            (city) => city.CityChapter_ID === user?.CCH?.CityChapter_ID
        );
    }

    const cellModifier = {
        AlumniCityChapterMap: ({ row }) => (
            <Link to={'/city-chapter-map'} state={row.original.CityChapter_ID}>
                City chapter Alumni list
            </Link>
        ),
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.cityChapters}
                Form={CityChapterForm}
                {...viewProps.CityChapters}
                cellModifier={cellModifier}
                irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}
                // downloadable={false}
                {...fetchUtils}
                response={
                    cityChapters.length ? cityChapters : fetchUtils.response
                }
            />
        </>
    );
}

export default CityChapters;
