import React from 'react';
import { Col, Row } from 'react-bootstrap';
import endpoints from '../../endpoints';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import AlumniJobDomain from './AlumniJobDomain';
import Chip from './Chip';
import ECMembers from './ECMembers';
import EntreInd from './EntreInd';
import AlumniGraduated from './AlumniGraduated';
import CityChapterInclusion from './CityChapterInclusion';
import Loader from '../../components/Loader';

function Dashboard() {
    const { response, loading } = useAxiosGetMultiple(
        {
            basics: endpoints.statistics.basics,
            ecMembers: endpoints.statistics.ecMembers,
            alumniJobIndustry: endpoints.statistics.alumniJobIndustry,
            alumniJobOrg: endpoints.statistics.alumniJobOrg,
            entreByIndustry: endpoints.statistics.entreByIndustry,
            alumniPerYear: endpoints.statistics.alumniPerYear,
            cityChapterInclusion: endpoints.statistics.cityChapterInclusion,
        }
        // { batch: false }
    );

    const {
        basics,
        ecMembers,
        // cityChapterInclusion,
        alumniJobIndustry,
        alumniJobOrg,
        alumniPerYear,
        entreByIndustry,
    } = response;

    const cityChapterInclusion = response.cityChapterInclusion.filter(
        (data) => !!data.COUNT_ACTIVE_ALUMNI
    );

    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <div
                style={{
                    fontSize: '20px',
                    color: '#000',
                    marginBottom: '25px',
                }}
            >
                Overview
            </div>
            <Row>
                <Col md='8'>
                    <Row>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Total Alumni Registered'>
                                {basics[0]?.COUNT_ALUMNI_REGISTERED}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#DADDFC' title='City Chapter count'>
                                {basics[0]?.Total_Number_Of_CityChapter}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#CEE5D0' title='No. of Entrepreneurs'>
                                {basics[0]?.Total_Entrepreneurs}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Alumni looking for job'>
                                {basics[0]?.lookingforjob_count}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#DADDFC' title='Active help requests'>
                                {basics[0]?.Count_Active_HelpRequests}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#CEE5D0' title='Active Mark as Safe events'>
                                {basics[0]?.Count_Active_Markassafe_Events}
                            </Chip>
                        </Col>
                    </Row>
                </Col>

                <Col md='4'>
                    <ECMembers memberList={ecMembers} />
                </Col>

                <Col md='8' className='gy-5'>
                    <AlumniJobDomain
                        data={{
                            industry: alumniJobIndustry,
                            organization: alumniJobOrg,
                        }}
                    />
                </Col>
                <Col md='4' className='gy-5'>
                    <EntreInd data={entreByIndustry} />
                </Col>

                    <Col md='6' className='gy-5'>
                        <AlumniGraduated data={alumniPerYear} />
                    </Col>
                {/* {cityChapterInclusion.length !== 0 && ( */}
                    <Col md='6' className='gy-5'>
                        <CityChapterInclusion data={cityChapterInclusion} />
                    </Col>
                {/* )} */}
            </Row>
        </>
    );
}

export default Dashboard;
