import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import CityChapterForm from './CityChapterAlumniMapForm';
import { useLocation } from 'react-router-dom';
import DeleteWithWarning from '../../components/DeleteWithWarning';

function CityChapterAlumniMap() {
    const fetchUtils = useAxiosGet(endpoints.cityChapterAlumniMap.get);

    const { state: cityId } = useLocation();

    let response = [];
    let cityChap = '';
    if (!fetchUtils.loading) {
        response =
            fetchUtils.response.find(
                (cityChap) => Number(cityChap.CityChapter_ID) === Number(cityId)
            )?.MAPPING ?? [];
        cityChap =
            fetchUtils.response.find(
                (cityChap) => Number(cityChap.CityChapter_ID) === Number(cityId)
            )?.CityChapter_Name ?? '';
    }
    const cellModifier = {
        Delete: ({ row }) => (
            <div className='td text-center'>
                <DeleteWithWarning
                    title={'Alumni from City Chapter'}
                    configBody={{
                        Alumni_ID: row.original.ALUMNI_BATCH_ADM_CODE,
                        CityChapter_ID: cityId,
                    }}
                    className='mx-auto'
                    onAfterDelete={fetchUtils.reFetch}
                    endpoint={endpoints.cityChapterAlumniMap.delete}
                />
            </div>
        ),
    };

    return (
        <>
            <CRUDTable
                endpoints={endpoints.cityChapterAlumniMap}
                Form={CityChapterForm}
                {...viewProps.CityChapterAlumniMap}
                immutable
                irremovable
                {...fetchUtils}
                name={'City Chapter Alumni Mapping - ' + cityChap}
                response={response}
                cellModifier={cellModifier}
                addBtnLabel={`Add Alumni to City Chapter`}
            />
        </>
    );
}

export default CityChapterAlumniMap;
