import React, { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import AdminNavbar from '../components/navbar/AdminNavbar.js';
import Sidebar from '../components/Sidebar/Sidebar.js';
import { useAuthenticationState } from '../context/Auth.context.js';
import endpoints from '../endpoints.js';
import { setLocale } from 'yup';
import axios from 'axios';
import withAlert from '../components/withAlert.js';
import Loader from '../components/Loader.js';

const Dashboard = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();

    const { user } = useAuthenticationState();

    const [notifications, setNotifications] = useState([]);
    const [initialLoading, setInitialLoading] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor = '#FAFAFE';

        return () => {
            document.body.style.backgroundColor = 'white';
        };
    }, []);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    // notification api called here to prevent
    // collision with inner dashboard pages api calls

    const roleAndNotificationAPIMap = {
        ADMIN: endpoints.notification.admin,
        GCM: endpoints.notification.admin,
        DELEGATE: endpoints.notification.delegate + user.DELEGATE_BATCH_CODE,
        CCH: endpoints.notification.cch + user?.CCH?.CityChapter_ID,
        'DELEGATE+CCH':
            endpoints.notification.cchdelegate +
            '?BATCH_CODE=' +
            user?.DELEGATE_BATCH_CODE +
            '&&CITYCHAPTER_CODE=' +
            user.CCH?.CityChapter_ID,
    };

    const notificationApi = roleAndNotificationAPIMap[user.Role];

    const getNotification = async () => {
        try {
            const res = await axios.get(notificationApi);
            setNotifications(res.data.responseData);
        } catch (err) {
            props.showAlert(
                'error',
                err.response.data?.statusText || 'Something went wrong'
            );
        }
    };

    useEffect(() => {
        setInitialLoading(true);
        getNotification().finally(() => {
            setInitialLoading(false);
        });
    }, []);

    // setting yup default validation message

    setLocale({
        mixed: {
            required: 'Required',
            notType: ({ type, path }) => {
                switch (type) {
                    case 'number':
                        return `Must be a number`;
                    case 'string':
                        return `Must be a string`;
                    case 'date':
                        return `Given date type is improper`;
                    default:
                        return `Invalid value`;
                }
            },
        },
        string: {
            email: 'Provide valid email',
            url: 'Provide valid url',
        },
        number: {
            min: ({ min, path }) => `Must be minimum ${min}`,
        },
    });

    return (
        <>
            <Sidebar {...props} />

            <div
                style={{
                    // marginLeft: `${showSidebar ? '230px' : '0px'}`,
                    marginLeft: '230px',
                    position: 'relative',
                    transition: 'margin .25s',
                    height: '100vh',
                    overflow: 'auto',
                    padding: '0 40px',
                }}
                ref={mainContent}
            >
                <AdminNavbar
                    notifications={notifications}
                    refetchNotification={getNotification}
                />
                {initialLoading ? <Loader /> : <Outlet />}
                <footer className='py-4' />
            </div>
        </>
    );
};

export default withAlert(Dashboard);
