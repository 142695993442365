import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import PaginatedLineChart from './PaginatedLineChart';

function AlumniJobDomain({ data }) {
    const { industry, organization } = data;

    const alumniPerIndustry = {
        labels: industry?.formattedData?.labels,
        datasets: industry?.formattedData?.datasets,
    };

    let labelOrg = [];
    let counts = [];

    organization.forEach((obj) => {
        labelOrg.push(obj?.Organization_Name);
        counts.push(obj?.COUNT_ALUMNI);
    });

    const alumniPerOrg = {
        labels: labelOrg,
        datasets: [
            {
                data: counts,
            },
        ],
    };

    return (
        <Tab.Container defaultActiveKey='Industry'>
            <Row className='px-2'>
                <Col>
                    <div className='small h6 text-dark'>Alumni Job Domain</div>

                    <div className='text-muted' style={{ fontSize: '.75em' }}>
                        Based on Industry
                    </div>
                </Col>
                <Col md='5'>
                    <Nav variant='pills'>
                        <Nav.Item className=' ms-auto'>
                            <Nav.Link
                                type='button'
                                className='p-1 py-0'
                                eventKey='Industry'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Industry
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                type='button'
                                className='p-1 py-0 ms-2'
                                eventKey='Organization'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Organization
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>

            <Tab.Content className='bg-white rounded-smoother mt-3 card-shadow'>
                <Tab.Pane eventKey='Industry' title='Industry'>
                    {alumniPerIndustry?.labels?.length === 0 ||
                    alumniPerIndustry?.labels?.length === undefined ? (
                        <div className='p-5 '>
                            No job domain data based on industry has been provided
                        </div>
                    ) : (
                        <PaginatedLineChart
                            data={alumniPerIndustry}
                            dataPerPage={15}
                        />
                    )}
                </Tab.Pane>
                <Tab.Pane eventKey='Organization' title='Organization'>
                    {alumniPerOrg?.labels?.length === 0 ||
                    alumniPerOrg?.labels?.length === undefined ? (
                        <div className='p-5 '>
                            No job domain based on organization has been
                            provided
                        </div>
                    ) : (
                        <PaginatedLineChart
                            data={alumniPerOrg}
                            dataPerPage={10}
                        />
                    )}
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default AlumniJobDomain;
