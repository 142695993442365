import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import CourseForm from '../courses/CourseForm';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';

function BatchForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { response, loading, reFetch } = useAxiosGet(endpoints.course.get);

    const courses = response.filter((course) => Number(course.COURSE_STATUS) === 1);
    
    const submitHandler = (values, { setSubmitting }) => {
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert('error', err.response.data?.statusText || 'Something went wrong');
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        BATCH_NAME: '',
        BATCH_CODE: '',
        COURSE_ID: '',
    };

    if (loading) {
        return <Loader />;
    }
    const preSelectedOption = {};
    
    if (updateValues) {
        preSelectedOption.course = courses.find(
            (course) =>
                Number(course.COURSE_ID) === Number(initialFormValues.COURSE_ID)
        );
    }
    
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                BATCH_NAME: Yup.string().required(),
                COURSE_ID: Yup.string().required(),
                BATCH_CODE: Yup.number().min(1).required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='input'
                            required
                            label='Batch Code'
                            name='BATCH_CODE'
                        />
                    )}
                    <FormikControl
                        control='input'
                        required
                        label='Batch Name'
                        name='BATCH_NAME'
                    />
                    <FormikControl
                        control='select-add'
                        Form={CourseForm}
                        formProps={{
                            formSize: 'sm',
                            title: 'Course',
                            endpoint: endpoints.course.add,
                            onAfterSubmit: reFetch,
                        }}
                        defaultValue={preSelectedOption.course}
                        options={courses}
                        optionValue='COURSE_ID'
                        optionLabel='COURSE_NAME'
                        label='COURSE'
                        required
                        name='COURSE_ID'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(BatchForm);
