import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import endpoints from '../../endpoints';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import { useLabelProvider } from '../../context/LabelProvider';

function CityChapterHeadForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { setLabels } = useLabelProvider();

    useEffect(() => {
        setLabels('CityChapterHead');
    }, []);

    const { response, loading } = useAxiosGetMultiple({
        cityChapters: endpoints.cityChapters.get,
    });

    const { cityChapters } = response;

    const submitHandler = (values, { setSubmitting }) => {
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert('error', err.response.data?.statusText || 'Something went wrong');
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        CityChapter_ID: '',
        CCH_Alumni_ID: '',
        CCH_Elected_Date: '',
        DCCH_Alumni_ID: '',
        DCCH_Elected_Date: '',
    };

    const preSelectedOption = {};

    if (loading) {
        return <Loader />;
    }
    if (updateValues) {
        preSelectedOption.cityChapter = cityChapters.find(
            (cityChapter) =>
                Number(cityChapter.CityChapter_ID) ===
                Number(initialFormValues.CityChapter_ID)
        );
        preSelectedOption.CCH = preSelectedOption.cityChapter.MAPPING.find(
            (alumni) =>
                alumni.ALUMNI_BATCH_ADM_CODE === updateValues.CCH_Alumni_ID
        );
        preSelectedOption.DCCH = preSelectedOption.cityChapter.MAPPING.find(
            (alumni) =>
                alumni.ALUMNI_BATCH_ADM_CODE === updateValues.DCCH_Alumni_ID
        );
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                CityChapter_ID: Yup.string().required(),
                CCH_Alumni_ID: Yup.string().required(),
                CCH_Elected_Date: Yup.date()
                    .max(Date(), 'Cannot be a future date')
                    .required(),
                DCCH_Alumni_ID: Yup.string().required(),
                DCCH_Elected_Date: Yup.date()
                    .max(Date(), 'Cannot be a future date')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='select-advanced'
                            required
                            options={cityChapters}
                            onChange={(value) => {
                                setFieldValue(
                                    'CityChapter_ID',
                                    value.CityChapter_ID
                                );
                                setFieldValue('CCH_Alumni_ID', '');
                                setFieldValue('DCCH_Alumni_ID', '');
                            }}
                            optionValue='CityChapter_ID'
                            optionLabel='CityChapter_Name'
                            label='City Chapter'
                            name='CityChapter_ID'
                        />
                    )}

                    {values.CityChapter_ID && (
                        <>
                            <FormikControl
                                control='select-advanced'
                                required
                                name='CCH_Alumni_ID'
                                label='City Chapter Head'
                                options={
                                    cityChapters.find(
                                        (chapter) =>
                                            Number(values.CityChapter_ID) ===
                                            Number(chapter.CityChapter_ID)
                                    ).MAPPING
                                }
                                optionValue='ALUMNI_BATCH_ADM_CODE'
                                defaultValue={preSelectedOption.CCH}
                                optionLabel='ALUMNI_Name'
                            />
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='CCH_Elected_Date'
                            />

                            <FormikControl
                                control='select-advanced'
                                required
                                name='DCCH_Alumni_ID'
                                label='Deputy City Chapter Head'
                                options={
                                    cityChapters.find(
                                        (chapter) =>
                                            Number(values.CityChapter_ID) ===
                                            Number(chapter.CityChapter_ID)
                                    ).MAPPING
                                }
                                optionValue='ALUMNI_BATCH_ADM_CODE'
                                defaultValue={preSelectedOption.DCCH}
                                optionLabel='ALUMNI_Name'
                            />
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='DCCH_Elected_Date'
                            />
                        </>
                    )}

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(CityChapterHeadForm);
