import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import ModalWrapper from '../../components/ModalWrapper';
import PostWithWarning from '../../components/PostWithWarning';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import { useAuthenticationState } from '../../context/Auth.context';
import StatusIndicator from '../../components/StatusIndicator';

function AlumniLookingForJob() {
    const { user } = useAuthenticationState();

    let getApi = endpoints.alumniLookingForJob.get;

    if (['DELEGATE','DELEGATE+CCH'].includes(user.Role)) {
        getApi =
            endpoints.alumniLookingForJob.delegate + user.DELEGATE_BATCH_CODE;
    }

    const fetchUtils = useAxiosGet(getApi);

    const cellModifier = {
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={'Approve Job Request ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Job_Requirement_Status_Approve !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.alumniLookingForJob.respond}
                        configBody={{
                            Job_Requirement_Status_Approve: '1',
                            ALUMNI_JOB_CODE: row.original.ALUMNI_JOB_CODE,
                            CREATEDBY: row.original.CreatedBy,
                            NOTIFICATION_CONTENT:
                                'Job request has been approved',
                            JobRequirementDetails:
                                row.original.JobRequirementDetails,
                        }}
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Block Job Request?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Job_Requirement_Status_Approve !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.alumniLookingForJob.respond}
                        configBody={{
                            Job_Requirement_Status_Approve: '2',
                            ALUMNI_JOB_CODE: row.original.ALUMNI_JOB_CODE,
                            CREATEDBY: row.original.CreatedBy,
                            NOTIFICATION_CONTENT:
                                'Job request has been rejected',
                            JobRequirementDetails:
                                row.original.JobRequirementDetails,
                        }}
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        OpenToDomainChange: ({ value }) => {
            if (Number(value) === 2) {
                return 'No';
            }
            if (Number(value) === 1) {
                return 'Yes';
            }
            return value;
        },
        Job_Requirement_Status_Approve: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        OpenToRelocation: ({ value }) => {
            if (Number(value) === 2) {
                return 'No';
            }
            if (Number(value) === 1) {
                return 'Yes';
            }
            return value;
        },
        OpenTohaveProfileSharedToExternalRecruiters: ({ value }) => {
            if (Number(value) === 2) {
                return 'No';
            }
            if (Number(value) === 1) {
                return 'Yes';
            }
            return value;
        },
        IsJobRequirementStatusActive: ({ value }) => {
            if (Number(value) === 2) {
                return 'Inactive';
            }
            if (Number(value) === 1) {
                return 'Active';
            }
            return value;
        },
        ContactPreference: ({ value }) => {
            if (Number(value) === 2) {
                return 'No';
            }
            if (Number(value) === 1) {
                return 'Yes';
            }
            return value;
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniLookingForJob}
                {...viewProps.AlumniLookingForJob}
                cellModifier={cellModifier}
                // irremovable
                insertable={false}
                immutable
                {...fetchUtils}
            />
        </>
    );
}

export default AlumniLookingForJob;
