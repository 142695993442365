import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import AlumniPersonalTagForm from './AlumniPersonalTagForm';

function AlumniPersonalTag() {
    const fetchUtils = useAxiosGet(endpoints.alumniPersonalTag.get);
    const cellModifier = {
        MAPPING: ({ value }) => {
            return (
                <div
                    style={{
                        maxWidth: '500px',
                        overflow: 'auto',
                        paddingBottom: '5px',
                    }}
                >
                    {value.map((tag) => (
                        <div
                            key={tag.Alumni_Personal_Tag_Name}
                            className='p-2 me-2 d-inline-block small'
                            style={{
                                background: 'rgba(95, 179, 246, 0.1)',
                                borderRadius: '10px',
                            }}
                        >
                            {tag.Alumni_Personal_Tag_Name}
                        </div>
                    ))}
                </div>
            );
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniPersonalTag}
                Form={AlumniPersonalTagForm}
                {...viewProps.AlumniPersonalTag}
                cellModifier={cellModifier}
                addBtnLabel='Add Mapping'
                formSize='md'
                irremovable
                downloadable={false}
                {...fetchUtils}
            />
        </>
    );
}

export default AlumniPersonalTag;
