import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import endpoints from '../../endpoints';
import axios from 'axios';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';

function AlumniPersonalTagForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { response, loading } = useAxiosGetMultiple({
        alumni: endpoints.alumniDetail.getAlumniOnly,
        personalTag: endpoints.personalTags.get,
    });

    const { alumni, personalTag } = response;

    const submitHandler = (values, { setSubmitting }) => {
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = {
        ALUMNI_PERSONALTAG_ID_ARRAY: updateValues?.MAPPING || [],
        ALUMNI_BATCH_ADM_CODE: updateValues?.ALUMNI_BATCH_ADM_CODE || '',
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOption = {};

    if (updateValues) {
        preSelectedOption.alumni = alumni.find(
            (alumni) =>
                Number(alumni.ALUMNI_BATCH_ADM_CODE) ===
                Number(initialFormValues.ALUMNI_BATCH_ADM_CODE)
        );
        preSelectedOption.personalTag = personalTag.filter(
            (personalTag) =>
                !!initialFormValues?.ALUMNI_PERSONALTAG_ID_ARRAY?.find(
                    (tag) =>
                        Number(tag.Alumni_PersonalTag_ID) ===
                        Number(personalTag.Alumni_PersonalTag_ID)
                )
        );
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                ALUMNI_BATCH_ADM_CODE: Yup.string().required(),
                ALUMNI_PERSONALTAG_ID_ARRAY: Yup.array(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values }) => (
                <Form>
                    <FormikControl
                        control='select-advanced'
                        required
                        name='ALUMNI_BATCH_ADM_CODE'
                        label='ALUMNI'
                        options={alumni}
                        optionValue='ALUMNI_BATCH_ADM_CODE'
                        defaultValue={preSelectedOption.alumni}
                        optionLabel='ALUMNI_Name'
                        disabled={!!updateValues}
                    />
                    <FormikControl
                        disabled={!values.ALUMNI_BATCH_ADM_CODE}
                        keyProp='Alumni_PersonalTag_ID'
                        control='select-advanced'
                        name='ALUMNI_PERSONALTAG_ID_ARRAY'
                        label='Personal Tags'
                        options={personalTag}
                        defaultValue={preSelectedOption.personalTag}
                        isMulti
                        optionValue='Alumni_PersonalTag_ID'
                        optionLabel='Alumni_Personal_Tag_Name'
                    />
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(AlumniPersonalTagForm);
